import { AutomationTypeEnum } from '@/store/modules/automation/types'
import { CountryEnum, ModeEnum, PmsiTypeEnum } from '@/store/modules/settings/types'

export enum FilterDataTypeEnum {
  BIOLOGY = 'biology',
  CONFIDENCE = 'confidence',
  COMPLETION = 'completion',
  CONSTANT = 'constant',
  DATE = 'date',
  MEDICAL_UNIT = 'medicalUnit',
  SELECT = 'select',
  RANGE = 'range',
  TEXT = 'text',
  TEXT_CONTENT = 'textContent',
}
export enum FilterValueTypeEnum {
  SINGLE_VALUE = 'singleValue',
  MULTI_VALUE = 'multiValue',
}
interface FilterDataRange {
  less: boolean
  min: number
  interval: number
  max: number
  more: boolean
  unit: string
}
export interface LocalizedOption {
  value: string
  label: string
  country: CountryEnum
}
export interface FilterData {
  name: string
  type: FilterDataTypeEnum
  groupage?: boolean
  mode?: ModeEnum[]
  automationTypes?: AutomationTypeEnum[]
  pmsiTypes?: PmsiTypeEnum[]
  valueType?: FilterValueTypeEnum
  label?: string
  country?: CountryEnum[]
  completionName?: string
  format?: string
  isGlobalCondition?: string
  isGlobalOptions?: string[]
  isPlurial?: boolean
  isTranslated?: boolean
  notGlobalOptions?: string[]
  options?: string[]|LocalizedOption[]
  optionsLocalised?: boolean
  default?: string
  labels?: { [key: string|number]: string }
  range?: FilterDataRange
  subFilters?: FilterData[]
}

export type CompletionFilterItem = {
  name: string
  type: string
  value: string
  description: string
}
