<script>
import { SancareOcticon } from '@sancare/ui-frontend-commons'

import { PmsiTypeEnum } from '@/store/modules/settings/types'

import CriteriaDisplayer from './CriteriaDisplayer.vue'
import FilterBuilder from './FilterBuilder'

export default {
  components: {
    'filter-builder': FilterBuilder,
    'sancare-octicon': SancareOcticon,
    'criteria-displayer': CriteriaDisplayer
  },
  props: {
    internalOperator: { type: String, required: true },
    group: { type: Object, required: true },
    groupIsGlobal: { type: Boolean, default: false },
    mode: { type: String, required: true },
    pmsiType: {
      validator(value) {
        return Object.values(PmsiTypeEnum).includes(value)
      },
      default: PmsiTypeEnum.MCO
    },
  },
  emits: ['clear-message', 'delete-criteria-group'],
  data() {
    return {
      PmsiTypeEnum
    }
  },
  methods: {
    addCriteria({ type, value }) {
      if(['presentMedicalAct', 'absentMedicalAct'].indexOf(type) > -1){
        // Acts are always in uppercase: AAFA001, AGMP* etc...
        value = value.toUpperCase()
      }
      // check for duplicate
      if (this.group.criteriaList.find((criterion) => criterion.type === type && criterion.value === value)) {
        return
      }
      this.$emit('clear-message')
      this.$store.commit('addAdvancedCriteria', {
        criteriaGroupId: this.group.id,
        type,
        value
      })
      this.$store.dispatch('refreshStayList', {})
    },
    removeCriteria({ criteriaGroupId, criterion }) {
      this.$emit('clear-message')
      this.$store.commit('removeAdvancedCriteria', { criteriaGroupId, criterion })
      this.$store.dispatch('refreshStayList', {})
    },
    deleteCriteriaGroup() {
      if (!this.groupIsGlobal) {
        this.$emit('clear-message')
        this.$emit('delete-criteria-group', this.group.id)
        this.$store.dispatch('refreshStayList', {})
      }
    },
  }
}
</script>

<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col">
            Liste de critères{{ groupIsGlobal ? ' globaux' : '' }} :
          </div>
          <div
            v-if="!groupIsGlobal"
            class="col-auto saved-search-icon"
            @click="() => deleteCriteriaGroup(group.id)"
          >
            <sancare-octicon
              name="x"
              :width="17"
              :height="20"
            />
          </div>
        </div>
        <criteria-displayer
          :internal-operator="groupIsGlobal ? 'ET' : internalOperator"
          :criteria-group="group"
          :display-grouped-criteria="groupIsGlobal"
          :mode="mode"
          @remove-criteria="removeCriteria"
        />
        <hr>
        <filter-builder
          :is-global="groupIsGlobal"
          :mode="mode"
          :pmsi-type="pmsiType"
          @add-criteria="addCriteria"
        />
      </div>
    </div>
  </div>
</template>
