<script>
import { SancareOcticon } from '@sancare/ui-frontend-commons'
import {
  getDrugEventPrestationTypeLabel,
  getDrugEventPrestationTypes
} from '@sancare/ui-frontend-commons/src/misc/DrugEventHelper'
import { DrugEventType } from '@sancare/ui-frontend-commons/src/types/health-data'
import _ from 'lodash'

import {
  getChunkClasses,
  getChunkOrigin,
  tagAsFalseJustification,
  tagAsLegitJustification,
  toggleFalseJustificationTypes
} from '../chunkJustifications'
import { getHealthDataLabel } from '../health-data/documentLabels.js'

export default {
  components: {
    'sancare-octicon': SancareOcticon,
  },
  props: {
    isLoading: { type: Boolean, default: false },
    noValueLabel: { type: String, default: 'Pas de justification' },
    chunks: { type: Object, default: null },
    healthData: { type: Object, default: null },
    selection: { type: Object, default: null },
    savedSearches: { type: Array, default: null },
    displayedRumIdx: { type: Number, required: true },
    rumPredictedLabels: { type: Array, default: null },
    readonly: { type: Boolean, default: false }
  },
  emits: ['select-health-data'],
  data() {
    return {
      falseJustificationTypes: [
        ['negation', 'Signaler comme négation', 'circle-slash'],
        ['antecedent', 'Signaler comme antécédent', 'history'],
        ['doubt', 'Signaler comme passage incertain', 'question'],
        ['recurrence', 'Signaler comme motif récurrent', 'sync'],
        ['delete', 'Annuler le signalement', 'trash']
      ],
      showFalseJustificationTypes: {},
      DrugEventType
    }
  },
  computed: {
    hasContent() {
      return Boolean(
        this.chunks && (
          _.some(this.chunks.reports)
          || _.some(this.chunks.textualHealthEntries)
          || _.some(this.chunks.labResults)
          || _.some(this.chunks.categoricalLabResults)
          || _.some(this.chunks.drugEvents)
          || _.some(this.chunks.healthConstants)
        )
      )
    },
    preparedHealthData() {
      if (!this.chunks) {
        return null
      }

      const preparedHealthData = {
        reports: _.map(
          _.filter(this.healthData.reports, (rep) => this.chunks && this.chunks.reports && this.chunks.reports[rep.id]),
          (rep) => {
            const newReport = {
              ...rep,
              paragraphs: [],
            }
            let currentParagraph = { chunks: [], hash: null }
            _.forEach(this.chunks.reports[rep.id], (chunk, chunkIdx) => {
              const isLast = this.chunks.reports[rep.id].length - 1 === chunkIdx
              const isFirst = chunkIdx === 0
              if (chunk.string.length >= 500 && !chunk.isHighlighted) {
                if (isFirst) {
                  // First chunk and irrelevant, we only keep the end
                  currentParagraph.chunks.push({
                    ...chunk,
                    string: `...${chunk.string.slice(-200)}`,
                    predictionMatches: _.find(rep.chunks, { chunkEnd:chunk.chunkEnd }).predictionMatches,
                    savedSearches: _.find(rep.chunks, { chunkEnd:chunk.chunkEnd }).savedSearches,
                  })
                  currentParagraph.hash = chunk.hash
                } else if (isLast) {
                  // Last chunk and irrelevant, we only keep the beginning
                  currentParagraph.chunks.push({
                    ...chunk,
                    string: `${chunk.string.slice(0, 200)}...`,
                    predictionMatches: _.find(rep.chunks, { chunkEnd:chunk.chunkEnd }).predictionMatches,
                    savedSearches: _.find(rep.chunks, { chunkEnd:chunk.chunkEnd }).savedSearches,
                  })
                  currentParagraph.hash = chunk.hash
                  newReport.paragraphs.push(currentParagraph)
                } else {
                  // Irrelevant chunk, we cut it, put the beginning in the previous paragraph and the end in a new paragraph
                  currentParagraph.chunks.push({
                    ...chunk,
                    string: `${chunk.string.slice(0, 200)}...`,
                    predictionMatches: _.find(rep.chunks, { chunkEnd:chunk.chunkEnd }).predictionMatches,
                    savedSearches: _.find(rep.chunks, { chunkEnd:chunk.chunkEnd }).savedSearches,
                  })
                  newReport.paragraphs.push(currentParagraph)
                  currentParagraph = { chunks: [{
                    ...chunk,
                    string: `...${chunk.string.slice(-200)}`,
                    predictionMatches: _.find(rep.chunks, { chunkEnd:chunk.chunkEnd }).predictionMatches,
                    savedSearches: _.find(rep.chunks, { chunkEnd:chunk.chunkEnd }).savedSearches,
                  }],
                  hash: chunk.hash
                  }
                }
              } else {
                // Relevant chunk, it's added to the current paragraph
                currentParagraph.chunks.push({
                  ...chunk,
                  predictionMatches: _.find(rep.chunks, { chunkEnd:chunk.chunkEnd }).predictionMatches,
                  savedSearches: _.find(rep.chunks, { chunkEnd:chunk.chunkEnd }).savedSearches,
                })
                currentParagraph.hash = chunk.hash
                if (isLast) {
                  newReport.paragraphs.push(currentParagraph)
                }
              }
            })
            return newReport
          }
        ),
      }

      // medicalHistoryEntries, nurseReportEntries and labResultCommentEntries are aggregated together in textualHealthEntries,
      // so we have to treat them separately
      _.forEach(['medicalHistory', 'nurseReport', 'targetNurseReport', 'labResultComment', 'surgeryLabel'], (sourceType) => {
        preparedHealthData[sourceType] = _.map(
          _.filter(this.healthData[sourceType], (itm) => this.chunks && this.chunks.textualHealthEntries && this.chunks.textualHealthEntries[itm.id]),
          (itm) => {
            const newItem = {
              ...itm,
              paragraphs: [],
            }
            let currentParagraph = { chunks: [], hash: null }
            _.forEach(this.chunks.textualHealthEntries[itm.id], (chunk, chunkIdx) => {
              const isLast = this.chunks.textualHealthEntries[itm.id].length - 1 === chunkIdx
              const isFirst = chunkIdx === 0
              if (chunk.string.length >= 300 && !chunk.isHighlighted) {
                if (isFirst) {
                  // First chunk and irrelevant, we only keep the end
                  currentParagraph.chunks.push({
                    ...chunk,
                    string: `...${chunk.string.slice(-300)}`,
                    predictionMatches: _.find(itm.chunks, { chunkEnd:chunk.chunkEnd }).predictionMatches,
                    savedSearches: _.find(itm.chunks, { chunkEnd:chunk.chunkEnd }).savedSearches,
                  })
                  currentParagraph.hash = chunk.hash
                } else if (isLast) {
                  // Last chunk and irrelevant, we only keep the beginning
                  currentParagraph.chunks.push({
                    ...chunk,
                    string: `${chunk.string.slice(0, 300)}...`,
                    predictionMatches: _.find(itm.chunks, { chunkEnd:chunk.chunkEnd }).predictionMatches,
                    savedSearches: _.find(itm.chunks, { chunkEnd:chunk.chunkEnd }).savedSearches,
                  })
                  currentParagraph.hash = chunk.hash
                  newItem.paragraphs.push(currentParagraph)
                } else {
                  // Irrelevant chunk, we cut it, put the beginning in the previous paragraph and the end in a new paragraph
                  currentParagraph.chunks.push({
                    ...chunk,
                    string: `${chunk.string.slice(0, 300)}...`,
                    predictionMatches: _.find(itm.chunks, { chunkEnd:chunk.chunkEnd }).predictionMatches,
                    savedSearches: _.find(itm.chunks, { chunkEnd:chunk.chunkEnd }).savedSearches,
                  })
                  newItem.paragraphs.push(currentParagraph)
                  currentParagraph = { chunks: [{
                    ...chunk,
                    string: `...${chunk.string.slice(-300)}`,
                    predictionMatches: _.find(itm.chunks, { chunkEnd:chunk.chunkEnd }).detectionMatches,
                    savedSearches: _.find(itm.chunks, { chunkEnd:chunk.chunkEnd }).savedSearches,
                  }],
                  hash: chunk.hash }
                }
              } else {
                // Relevant chunk, it's added to the current paragraph
                currentParagraph.chunks.push({
                  ...chunk,
                  detectionMatches: _.find(itm.chunks, { chunkEnd:chunk.chunkEnd }).detectionMatches,
                  savedSearches: _.find(itm.chunks, { chunkEnd:chunk.chunkEnd }).savedSearches,
                })
                currentParagraph.hash = chunk.hash
                if (isLast) {
                  newItem.paragraphs.push(currentParagraph)
                }
              }
            })
            return newItem
          }
        )
      })

      // In those sources, we do not store a list of chunks, but a single boolean.
      _.forEach(['labResults', 'categoricalLabResults', 'drugEvents', 'healthConstants'], (sourceType) => {
        preparedHealthData[sourceType] = _.filter(this.healthData[sourceType], (itm) => this.chunks && this.chunks[sourceType] && this.chunks[sourceType][itm.id])
      })

      return preparedHealthData
    },
    nonEmptySourceTypes() {
      return _.filter(['medicalHistory', 'nurseReport', 'targetNurseReport', 'labResultComment', 'surgeryLabel'], (sourceType) => this.preparedHealthData[sourceType].length)
    },
    drugEventPrestationTypes() {
      return getDrugEventPrestationTypes(this.preparedHealthData.drugEvents)
    }
  },
  methods: {
    getHealthDataLabel(type) {
      return getHealthDataLabel(type)
    },
    getChunkClasses(chunk) {
      return getChunkClasses(chunk, this.selection, this.displayedRumIdx)
    },
    handleHealthDataSelection(mainContent) {
      this.$emit('select-health-data', mainContent)
    },
    tagAsFalseJustification({ report, healthEntry, errorType, paragraph }) {
      const chunks = _.filter(paragraph.chunks, ['isHighlighted', true])

      _.forEach(chunks, (chunk) => {
        const origin = getChunkOrigin(chunk, this.rumPredictedLabels, this.savedSearches, this.displayedRumIdx)
        this.showFalseJustificationTypes[chunk.hash] = tagAsFalseJustification(report, healthEntry, chunk, errorType, origin, this.$store)
        toggleFalseJustificationTypes(chunk, this.showFalseJustificationTypes)
      })
    },
    tagAsLegitJustification({ report, healthEntry, paragraph }) {
      const chunks = _.filter(paragraph.chunks, ['isFalseJustification', true])
      _.forEach(chunks, (chunk) => {
        this.showFalseJustificationTypes[chunk.hash] = tagAsLegitJustification(report, healthEntry, chunk, this.$store)
        toggleFalseJustificationTypes(chunk, this.showFalseJustificationTypes)
      })
    },
    canTagFalseJustifications(chunks) {
      return _.some(chunks, { isHighlighted: true, isFalseJustification: false })
    },
    canDeleteFalseJustifications(chunks) {
      return _.some(chunks, 'isFalseJustification')
    },
    canDisplayIcon(chunks, iconType) {
      if (iconType === 'delete') {
        return _.some(chunks, 'isFalseJustification')
      } else {
        return _.some(chunks, { isHighlighted: true, isFalseJustification: false })
      }
    },
    updateJustificationStatus({ errorType, report, healthEntry, paragraph }) {
      if (errorType === 'delete') {
        this.tagAsLegitJustification({ report, healthEntry, paragraph })
      } else {
        this.tagAsFalseJustification({ report, healthEntry, errorType, paragraph })
      }
    },
    getDrugEventPrestationTypeLabel
  },
}
</script>

<template>
  <div class="justification-popover">
    <div v-if="!isLoading && hasContent">
      <div v-if="preparedHealthData.reports.length">
        <div
          v-for="report in preparedHealthData.reports"
          :key="report.id"
          class="justification-part"
        >
          <h3
            class="btn btn-sm btn-report-select"
            @click="handleHealthDataSelection({ type: 'report', targetId: report.id })"
          >
            {{ report.documentTitle || `[ Sans Titre ]` }}
          </h3>
          <div
            class="mx-1 popover-report-content align-items-center text-data-content"
          >
            <div
              v-for="(paragraph, paragraphIdx) in report.paragraphs"
              :key="paragraph.hash"
              class="row align-items-start"
            >
              <div class="col">
                <div>
                  <span
                    v-for="chunk in paragraph.chunks"
                    :key="chunk.hash"
                    :class="getChunkClasses(chunk)"
                    @click="handleHealthDataSelection({ type: 'report', targetId: report.id })"
                  >{{ chunk.string }}</span>
                </div>
                <div
                  v-if="paragraphIdx < report.paragraphs.length - 1"
                  class="my-3"
                >
                  [...]
                </div>
              </div>
              <div
                v-if="!readonly"
                class="col-auto"
              >
                <span
                  v-for="(signal, signalIdx) in falseJustificationTypes"
                  :key="signal[0]"
                  v-tooltip="signal[1]"
                  @click="updateJustificationStatus({ errorType: signal[0], report : report, healthEntry: null, paragraph: paragraph})"
                >
                  <!-- Breaks line every two items to save space -->
                  <div
                    v-if="0 === signalIdx %2"
                    class="w-100"
                  >
                    <!-- -->
                  </div>
                  <sancare-octicon
                    v-if="canDisplayIcon(paragraph.chunks, signal[0])"
                    class="btn btn-icon"
                    :name="signal[2]"
                    :width="20"
                    :height="20"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-for="sourceType in nonEmptySourceTypes"
        :key="sourceType"
        class="justification-part"
      >
        <h3
          class="btn btn-sm btn-report-select"
          @click="handleHealthDataSelection({ type: sourceType, targetId: null })"
        >
          {{ getHealthDataLabel(sourceType) }}
        </h3>
        <div
          v-for="(item, itemIdx) in preparedHealthData[sourceType]"
          :key="`${sourceType}-${item.id}`"
          class="justification-part-line"
        >
          <hr v-if="itemIdx > 0">
          <div class="mx-1 popover-report-content align-items-center">
            <div class="col-3">
              {{ item.creationDate ? item.creationDate.format('L') : '-' }}
            </div>
            <div
              v-for="(paragraph, paragraphIdx) in item.paragraphs"
              :key="paragraph.hash"
              class="row align-items-start text-data-content"
            >
              <div class="col">
                <div>
                  <span
                    v-for="chunk in paragraph.chunks"
                    :key="chunk.hash"
                    :class="getChunkClasses(chunk)"
                    @click="handleHealthDataSelection({ type: sourceType, targetId: null })"
                  >{{ chunk.string }}</span>
                </div>
                <div
                  v-if="paragraphIdx < item.paragraphs.length - 1"
                  class="my-3"
                >
                  [...]
                </div>
              </div>
              <div>
                <span
                  v-for="(signal, signalIdx) in falseJustificationTypes"
                  :key="signal[0]"
                  v-tooltip="signal[1]"
                  @click="updateJustificationStatus({ errorType: signal[0], report : null, healthEntry: item, paragraph: paragraph})"
                >
                  <!-- Breaks line every two items to save space -->
                  <div
                    v-if="0 === signalIdx %2"
                    class="w-100"
                  >
                    <!-- -->
                  </div>
                  <sancare-octicon
                    v-if="canDisplayIcon(paragraph.chunks, signal[0])"
                    class="btn btn-icon"
                    :name="signal[2]"
                    :width="20"
                    :height="20"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="preparedHealthData.drugEvents.length"
        class="justification-part"
      >
        <template
          v-for="type in drugEventPrestationTypes"
          :key="type"
        >
          <h3
            class="btn btn-sm btn-report-select"
            @click="handleHealthDataSelection({ type: 'drugEvent', targetId: null, drugEventPrestationType: type })"
          >
            {{ getDrugEventPrestationTypeLabel(type) }}
          </h3>
          <div
            v-for="event in preparedHealthData.drugEvents.filter((drugEvent) => drugEvent.prestationType === type)"
            :key="event.id"
            class="justification-part-line"
          >
            <div class="row mx-2 my-1 align-items-center">
              <span class="col-2">{{ event.creationDate ? event.creationDate.format('L') : '-' }}</span>
              <div class="col hinted-summary-line">
                <div class="container py-2">
                  <span class="row">{{ DrugEventType[event.type] }}</span>
                  <span class="row">{{ event.drug.atc }}</span>
                  <span class="row">{{ event.drug.commercialName }}</span>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div
        v-if="preparedHealthData.healthConstants.length"
        class="justification-part"
      >
        <h3
          class="btn btn-sm btn-report-select"
          @click="handleHealthDataSelection({ type: 'constant', targetId: null })"
        >
          Constantes
        </h3>
        <div
          v-for="(constant, healthIdx) in preparedHealthData.healthConstants"
          :key="constant.id"
          class="justification-part-line"
        >
          <hr v-if="healthIdx > 0">
          <div class=" row mx-1 popover-report-content align-items-center">
            <span class="col-3">{{ constant.creationDate ? constant.creationDate.format('L') : '-' }}</span>
            <div class="hinted-summary-line">
              <span class="col-6">{{ constant.constantType.description }}</span>
              <span class="col-3">{{ constant.value }}</span>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="preparedHealthData.labResults.length || preparedHealthData.categoricalLabResults.length"
        class="justification-part"
      >
        <h3
          class="btn btn-sm btn-report-select"
          @click="handleHealthDataSelection({ type: 'labResult', targetId: null })"
        >
          Biologie
        </h3>
        <div
          v-for="result in preparedHealthData.labResults"
          :key="result.id"
          class="justification-part-line"
        >
          <div class="row mx-1 popover-report-content align-items-center">
            <span class="col-3">{{ result.creationDate ? result.creationDate.format('L') : '-' }}</span>
            <div class="hinted-summary-line">
              <span class="col-4">{{ result.title }}</span>
              <span class="col-2">{{ result.code }}</span>
              <span class="col-3">{{ result.value }}</span>
            </div>
          </div>
        </div>
        <div
          v-for="result in preparedHealthData.categoricalLabResults"
          :key="result.id"
          class="justification-part-line"
        >
          <div class="row mx-1 popover-report-content align-items-center">
            <span class="col-3">{{ result.creationDate ? result.creationDate.format('L') : '-' }}</span>
            <div class="hinted-summary-line">
              <span class="col-4">{{ result.title }}</span>
              <span class="col-2">{{ result.code }}</span>
              <span class="col-3">{{ result.value }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="isLoading">
      <div class="loader loader-center mb-4" />
      <p class="">
        Calcul des justifications...
      </p>
    </div>
    <div v-else>
      <p class="justification-message">
        {{ noValueLabel }}
      </p>
    </div>
  </div>
</template>
