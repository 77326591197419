<script>
import { SancareOcticon } from '@sancare/ui-frontend-commons'
import dayjs from '@sancare/ui-frontend-commons/src/misc/dayjs'
import _ from 'lodash'
import { mapState } from 'vuex'

import useI18n from '@/i18n'

import { documentCategories, textualHealthDataCategories } from '../stay-displayer/health-data/documentLabels.js'
import filtersData from './FiltersData'
import MultipleValueFilter from './MultipleValueFilter.vue'
const t = useI18n().global.t

const makeRangeLabel = (lowValue, highValue, suffix = '') => {
  if (!lowValue || lowValue[0] === '<') {
    return `inférieur ou égal à ${highValue}${suffix}`
  }
  if (!highValue || highValue[0] === '>') {
    return `supérieur ou égal à ${lowValue}${suffix}`
  }

  return `de ${lowValue} à ${highValue}${suffix}`
}

export default {
  components: {
    'sancare-octicon': SancareOcticon,
    'multiple-value-filter': MultipleValueFilter,
  },
  props: {
    canRemoveCriteria: { type: Boolean, default: true },
    criteriaGroup: { type: Object, required: true },
    internalOperator: { type: String, required: true },
    displayGroupedCriteria: { type: Boolean, default: false }, // group criteria with same type
    isGlobal: { type: Boolean, required: false, default: false }, // is global criteria
    maxCriteria: { type: Number, default: 0 },
    mode: { type: String, required: true },
  },
  emits: ['remove-criteria'],
  data() {
    return {
      criteriaData: filtersData,
      textualReportCategories: { ...documentCategories, ...textualHealthDataCategories },
    }
  },
  computed: {
    criteriaList() {
      const criteriaList = {}
      _.forEach(this.criteriaGroup.criteriaList, (currentField) => {
        const criteriaType = currentField.type
        const currentValue = currentField.value

        if (currentValue) {
          const label = this.getCriteriaLabel(criteriaType)
          if (criteriaList[criteriaType]) {
            criteriaList[criteriaType].currentValues.push({
              id: currentField.id,
              rawContent: currentValue,
              content: this.renderCriteria(criteriaType, currentValue),
              type:criteriaType,
              modifiers: this.getModifiers(currentValue)
            })
          } else {
            criteriaList[criteriaType] = {
              label,
              currentValues: [{
                id: currentField.id,
                rawContent: currentValue,
                content: this.renderCriteria(criteriaType, currentValue),
                type:criteriaType,
                modifiers: this.getModifiers(currentValue)
              }]
            }
          }
        }
      })

      return criteriaList
    },
    ...mapState({
      healthConstantsList: (state) =>  state.medicalUnitFilter.remoteLists['constants'],
      pmsiCountry: (state) => state.settings.pmsi.country,
    }),
  },
  methods: {
    getCriteriaLabel(rawCriteriaType) {
      const splitCriteriaType = rawCriteriaType.split('__')
      const criteriaType = splitCriteriaType[0]
      if(splitCriteriaType.length > 1){
        return `${t(`filter.labels.${criteriaType}`)} (${this.textualReportCategories[splitCriteriaType[1]].toLowerCase()})`
      }
      return t(`filter.labels.${criteriaType}`)
    },
    getConstantTitle(constantId) {
      const constant = _.find(this.healthConstantsList, (c) => c.id === Number(constantId))

      return constant ? constant.description : ''
    },
    renderCriteria(rawType, value) {
      if (!value) {
        return ''
      }
      const type = rawType.indexOf('__') === -1 ? rawType : rawType.split('__')[0]

      switch (type) {
        case 'rumCount':
          value = value.split('_')
          return `${value[0]} à ${value[1]}`

        case 'presentContent':
        case 'absentContent':
        // If there is a \x1F separator between the modifiers and the text, we show what's after the separator
        // If there is no \x1F, then the substring will start from 0, effectively doing nothing.
          return value.substring(value.indexOf('\x1F') + 1)

        case 'stayDuration':
          value = value.split('_')
          // We replace 0 by null, to avoid having a label like "0j to 2j" but rather "<2j"
          return makeRangeLabel(value[0] === '0' ? null : value[0], value[1], 'j')

        case 'patientAge':
          value = value.split('_')
          return makeRangeLabel(value[0] === '0' ? null : value[0], value[1], ' ans')

        case 'startingDmsGap':
        case 'currentDmsGap':
          value = value.split('_')
          return makeRangeLabel(value[0], value[1], 'j')

        case 'healthConstant':
          value = value.split('\x1F')
          return `${this.getConstantTitle(value[0])} ${makeRangeLabel(value[1], value[2])}`

        case 'biologyResult':
          value = value.split('\x1F')
          if (value.length === 3) {
            return `${value[0]} ${makeRangeLabel(value[1], value[2])}`
          }

          return `${value[0]} à ${value[1]}`

        case 'compareDiagnosis':
        case 'gender':
        case 'hasSession':
        case 'releaseMode':
        case 'admissionMode':
        case 'origin':
        case 'destination':
          if (this.criteriaData[rawType].optionsLocalised) {
            return this.criteriaData[rawType].options.find((opt) => opt.country === this.pmsiCountry && opt.value === value).label
          }
          return this.criteriaData[rawType]['labels'][value]

        case 'fromStayDate':
          value = dayjs(value).format('L')

          return `À partir du ${value}`
        case 'toStayDate':
          value = dayjs(value).format('L')

          return `Jusqu'au ${value}`

        case 'presentDocumentCategory':
        case 'absentDocumentCategory':

          return documentCategories[value] ?? textualHealthDataCategories[value]

        default:
          return value
      }
    },
    getModifiers(value) {
      if (value.indexOf('\x1F') === -1) {
        return {}
      }

      const modifiers = {
        includeAntecedents: false,
        includeFamilyAntecedents: false,
        wordDistance: -1,
      }
      const strModif = value.substring(0, value.indexOf('\x1F'))
      const strModifSplit = strModif.split('_')

      _.forEach(strModifSplit, (modif) => {
        if (modif === 'a') {
          modifiers.includeAntecedents = true
        } else if (modif === 'f') {
          modifiers.includeFamilyAntecedents = true
        } else if (modif.substring(0, 2) === 'wd') {
          modifiers.wordDistance = modif.substring(2)
        }
      })

      return modifiers
    },
    removeCriteria(criterion) {
      this.$emit('remove-criteria', { criteriaGroupId: this.criteriaGroup.id, criterion })
    },
    selectDisplayedCriteria(values) {
      if (this.maxCriteria > 0) {
        values = values.slice(0, this.maxCriteria)
      }

      return values.map((criterion) => {
        if (['presentContent', 'absentContent'].indexOf(criterion.type) < 0) {
          return criterion
        }

        criterion.value = criterion.value || criterion.rawContent

        return {
          id: criterion.id,
          type: criterion.type,
          rawContent: criterion.value,
          content: this.renderCriteria(criterion.type, criterion.value),
          modifiers: this.getModifiers(criterion.value)
        }
      })
    },
  }
}
</script>

<template>
  <div name="CriteriaDisplayer">
    <div v-if="displayGroupedCriteria">
      <div
        v-for="(criteria, criteriaName) in criteriaList"
        :key="`criteria_list_${criteriaName}`"
        class="row no-gutters align-items-center"
      >
        <div
          v-if="criteriaName === 'exclusiveMedicalAct'"
          class="col-12 mb-3 font-italic text-secondary"
        >
          Les séjours doivent posséder uniquement des actes présents dans la liste d'actes exclusifs suivante :
        </div>
        <div class="col-5 px-2 criteria-label text-right">
          {{ criteria.label }}
        </div>
        <div class="col row no-gutters px-0 py-1">
          <div v-if="criteria.currentValues">
            <div
              v-for="(value, idx) in selectDisplayedCriteria(criteria.currentValues)"
              :key="`criteria__${criteriaName}__${idx}`"
              class="col-auto mx-1 mb-1 pl-2 pr-2 badge text-wrap"
              :class="isGlobal ? ['badge-highlight'] : ['badge-primary']"
            >
              <multiple-value-filter
                :filter="value"
                :can-edit="canRemoveCriteria"
                @remove-criteria="removeCriteria"
              />
            </div>
            <p v-if="maxCriteria > 0 && criteria.currentValues.length > maxCriteria">
              ...
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else-if="(!criteriaGroup.criteriaList || criteriaGroup.criteriaList.length === 0)"
      class="text-center font-italic text-secondary"
    >
      <small>Ce groupe ne contient aucun critère.</small>
    </div>
    <div
      v-else
      class="container"
    >
      <div
        v-for="(criterion, criterionIdx) in selectDisplayedCriteria(criteriaGroup.criteriaList)"
        :key="`criteria_list_${criterionIdx}`"
        class="text-center row"
      >
        <span
          class="internal-operator col-auto ml-2"
        >
          {{ criterionIdx > 0 ? internalOperator : '' }}
        </span>
        <div class="col-auto">
          <span
            class="badge text-wrap"
            :class="isGlobal ? ['badge-highlight'] : ['badge-primary']"
          >
            <span v-if="['presentContent', 'absentContent'].indexOf(criterion.type) < 0">
              {{ getCriteriaLabel(criterion.type) }} : {{ renderCriteria(criterion.type, criterion.value) }}
              <span
                v-if="canRemoveCriteria"
                class="criteria-remove"
                @click="removeCriteria(criterion)"
              >
                <sancare-octicon
                  name="x"
                  :width="14"
                  :height="11"
                />
              </span>
            </span>
            <span v-else>
              {{ getCriteriaLabel(criterion.type) }} :
              <multiple-value-filter
                :filter="criterion"
                :can-edit="canRemoveCriteria"
                @remove-criteria="removeCriteria"
              />
            </span>
          </span>
        </div>
      </div>
      <p
        v-if="maxCriteria > 0 && criteriaGroup.criteriaList.length > maxCriteria"
        class="text-center"
      >
        ...
      </p>
    </div>
  </div>
</template>

<style lang="less">
.internal-operator {
  padding: 0;
  min-width: 50px;
}
</style>
