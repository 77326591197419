import { createI18n } from 'vue-i18n'

import localesCh from '@/locales/ch.json'
import localesFr from '@/locales/fr.json'

type MessageSchema = typeof localesCh
import { CountryEnum } from '@/store/modules/settings/types'

declare global {
  interface Window {
    pmsiCountry: CountryEnum
  }
}

const i18n = createI18n<[MessageSchema], CountryEnum>({
  legacy: false,
  locale: window.pmsiCountry || 'fr',
  fallbackLocale: 'fr',
  messages: {
    ch: localesCh,
    fr: localesFr,
  }
})
export default function useI18n() {
  return i18n
}
